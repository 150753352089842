body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  font-family: "Rokkitt", "sans-serif";
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
h1,
h2,
h3,
h4 {
  font-family: "Cardo", "serif";
}

#dotapick {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}
