@mixin bgRadialGradient($color1, $color2) {
  background: radial-gradient(circle at center, $color1, $color2);
//   background: radial-gradient($color2, $color1);
}

@mixin lightBgRadialGradient($color1, $color2) {
  background: radial-gradient($color1, $color2);
  color: black;
  .static-text{
      color: black;
  }
  .dota-gas {
    input,
    label {
      color: black;
    }
  }
}

#heroSelect {
   .MuiAutocomplete-clearIndicator{
      color: white;
   }
   background-blend-mode: overlay;
  &.anti-mage {
    background-color: #753B8B;
    @include bgRadialGradient(#753B8B, #000000);
  }
  &.venomancer {
    @include lightBgRadialGradient(greenyellow, green )
  }
}
